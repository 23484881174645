import Utils from "../../../utils";

export default class WarehouseLocationPalletEntity {
    static getEntityData(elem: any) {
        return {
            id: elem.querySelector("#warehouse_location_pallet_id") ? (elem.querySelector("#warehouse_location_pallet_id") as HTMLInputElement).value : null,
            name: (elem.querySelector("#warehouse_location_pallet_name") as HTMLInputElement).value,
            warehouse_location_id: (elem.querySelector("#warehouse_location_pallet_warehouse_location_id option:checked") as HTMLInputElement).value,
        }
    }
}